    export const environment = {
      production: true,
      api_url: window["env"]["apiUrl"] || 'http://cashboxapiclient.onoi.me/api/ru',
      api_identity: window["env"]["apiIdentity"] || 'https://cashboxauth.onoi.me/api',
      api_static: window["env"]["apiStatic"] || 'https://s3.yurta.me/',
      version: '3.0.0',
      globalLocation: window["env"]["globalLocation"] || 'c',
      globalCurrencyCode: window["env"]["globalCurrencyCode"] || 'KGS',
      globalCurrencySymbol: window["env"]["globalCurrencySymbol"] || 'C',
      defaultLang: window["env"]["defaultLang"] || 'ru'
    };
