<mat-progress-bar *ngIf="!isCompleted" mode="indeterminate"></mat-progress-bar>
<div *ngIf="template">

    <mat-nav-list>
        <mat-list-item class="template-service-block">
            <a routerLink="/">
                <mat-icon color="primary" mat-list-icon>arrow_back_ios</mat-icon>
            </a>

            <div>
                <mat-icon mat-list-icon class="template-service-icon"><img src="{{static_api_url}}services/read/{{id}}.png" height="40" /></mat-icon>
            </div>
            <span style="padding-left: 22px;">
                {{serviceName}}
                <br>
                <span style="color: grey;" class="mat-caption">{{categoryName}}</span>
            </span>
            <mat-divider></mat-divider>
        </mat-list-item>
    </mat-nav-list>


    <div *ngIf="seriesPaymentState < SeriesPaymentState.Selected">
        <form [formGroup]="form">
            <mat-card>
                <mat-card-content style="margin-bottom: 0;">
                    <ng-container *ngFor="let item of inputcontrols" class="form-row">
                        <app-templatecontrol *ngIf="!item.isForPay" [inputcontrol]="item" [inputPrefix]="inputPrefix" [inputMask]="inputMask" [form]="form"></app-templatecontrol>
                    </ng-container>
                    <br />
                    <mat-form-field fxFill *ngIf="recievedReference">
                        <input #amountel type="text" [formControl]="amount" (ngModelChange)="getCommission()" matInput
                            [readonly]="isInvoice || onDemandAmount" placeholder="{{'amount'| translate}}" (keypress)="onKeypressAmountEvent($event)" currencyMask>
                        <mat-error *ngIf="amount.errors">
                            <div *ngIf="amount.errors.required">{{'amount-req' | translate}}</div>
                            
                            <div *ngIf="amount.errors.min && template.minAmount/100 <= 0">{{'amount-min-value-default' | translate }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 <= 0">{{'amount-max-value-default' | translate }}</div>

                            <div *ngIf="amount.errors.min && template.minAmount/100 > 0">{{'amount-min-value' | translate: {amount: template.minAmount/100} }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 > 0 && id != 310036">{{'amount-max-value' | translate: {amount: template.maxAmount/100} }}</div>
                            <div *ngIf="amount.errors.max && template.maxAmount/100 > 0 && id == 310036">{{'amount-max-value' | translate: {amount: 490000} }}</div>
                        </mat-error>
                        <mat-hint style="display: flex;">{{'minAmount'| translate}}:
                            {{template.minAmount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}},
                            {{'maxAmount'| translate}}:
                            <span *ngIf="id != 310036"> {{template.maxAmount/100 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                            <span *ngIf="id == 310036"> {{490000 | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
                        </mat-hint>
                    </mat-form-field>
                    <ng-container *ngIf="recievedReference">
                        
                        <mat-slide-toggle class="include-commission-checkbox" fxFill color="primary" [formControl]="isIncludeCommission" checked="false"
                            *ngIf="!isInvoice && !onDemandAmount" (ngModelChange)=getCommission()>
                            {{ 'include-commission'| translate}}
                        </mat-slide-toggle>
                        <small>{{'commission' | translate}}
                            {{commission | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</small>                                                       
                            <button mat-icon-button color="primary" matTooltip="{{'view-upper-commission' | translate}}" (click)="openAgentUpperCommissionDialog(template)">
                                <mat-icon>contact_support</mat-icon>
                            </button>
                        <br />
                        <small>{{'lower-commission-short' | translate}}</small>                                                       
                        <button mat-icon-button color="primary" matTooltip="{{'view-lower-commission' | translate}}" (click)="openAgentLowerCommissionDialog(template)">
                            <mat-icon>contact_support</mat-icon>
                        </button>
                        <br>
                        <small>{{'calculated-amount' | translate}}
                            {{calculatedAmount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</small>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions>
                    <button *ngIf="!recievedReference" mat-flat-button fxFill [disabled]="!form.valid"
                        (click)="checkPay()"
                        color="primary">{{'check'| translate}}</button>
                    <button *ngIf="recievedReference" mat-flat-button fxFill (click)="pay()"
                        [disabled]="!amount.valid || calculatedAmount <= 0" color="primary">{{'pay'| translate}}
                        {{accepted | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</button>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
    <br>

    <!-- Начало Template для типа InvoiceTypeA, InvoiceTypeB, InvoiceTypeEnergiaRudny -->
    <app-details (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeA' || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeB'
        || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeEnergiaRudny')
        " [checkDetails]="checkDetails"></app-details>
    <!-- Конец Template для типа InvoiceTypeA, InvoiceTypeB, InvoiceTypeEnergiaRudny -->    
    
    <!-- Начало Template для типа InvoiceTypeC -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeC'">
        <app-invoice-type-cdetails (update)="countChange($event)" (changedServices)="billChanged($event)" [checkDetails]="checkDetails"></app-invoice-type-cdetails>
    </div>
    <!-- Конец Template для типа InvoiceTypeC -->

    <!-- Начало Template для типа InvoiceTypeOnlyLastCounts -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeOnlyLastCounts'">
        <app-invoice-type-only-last-counts-details (update)="countChange($event)" (changedServices)="billChanged($event)" [checkDetails]="checkDetails"></app-invoice-type-only-last-counts-details>
    </div>
    <!-- Конец Template для типа InvoiceTypeOnlyLastCounts -->

    <!-- Начало Template для типа SimpleWithDisplayInfo и ExtBalance -->
    <div *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'Simple' || checkDetails?.serviceExtInfo?.serviceType == 'SimpleWithDisplayInfo' || checkDetails?.serviceExtInfo?.serviceType == 'ExtBalance')">
        <div *ngIf="checkDetails?.addInfo?.accounts?.account">
            <div *ngFor="let item of checkDetails?.addInfo?.accounts?.account">
                <div *ngFor="let detail of item | keyvalue">
                    <mat-form-field fxFill *ngIf="detail.key != 'displayInfo' && detail.value.toString().length > 0">
                        <input matInput placeholder='{{detail.key | translate}}' value='{{detail.value}}' disabled>
                    </mat-form-field>
                </div>

                <div *ngFor="let detailLine of item.displayInfo?.line">
                    <mat-form-field fxFill>
                        <input matInput placeholder='' value='{{detailLine}}' disabled>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа SimpleWithDisplayInfo и ExtBalance -->

    <!-- Начало Template для типа ShowAgreements -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'ShowAgreements'">
        <div *ngFor="let agreement of checkDetails?.addInfo?.document?.agreements?.agreement">
            <div *ngFor="let detail of agreement | keyvalue">
                <mat-form-field fxFill *ngIf="detail.value.toString().length > 0">
                    <input matInput placeholder='{{detail.key | translate}}' value='{{detail.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа ShowAgreements -->

    <div *ngIf="recievedReference && checkDetails?.addInfo?.accounts?.accountsList">
        <div *ngFor="let item of checkDetails?.addInfo?.accounts?.accountsList[0] | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>
    </div>

    <!-- Начало Template для типа InvoiceTypeERCKU -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeERCKU'">
        <div *ngFor="let item of checkDetails?.addInfo?.document?.payer | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>
    </div>
    <!-- Конец Template для типа InvoiceTypeERCKU -->

    <!-- Начало Template для типа Tickets -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Tickets'">
        <div *ngFor="let item of checkDetails?.addInfo?.tickets?.ticket">
            <div *ngFor="let ticketItem of item | keyvalue">
                <mat-form-field fxFill *ngIf="ticketItem.value.toString().length > 0">
                    <input matInput placeholder='{{ticketItem.key | translate}}' value='{{ticketItem.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Tickets -->

    <!-- Начало Template для типа Tickets2 -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Tickets2'">
        <div *ngFor="let item of checkDetails?.addInfo?.document?.payer | keyvalue">
            <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
            </mat-form-field>
        </div>

        <div *ngFor="let item of checkDetails?.addInfo?.document?.tickets?.ticket">
            <div *ngFor="let ticketItem of item | keyvalue">
                <mat-form-field fxFill *ngIf="ticketItem.value.toString().length > 0">
                    <input matInput placeholder='{{ticketItem.key | translate}}' value='{{ticketItem.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Tickets2 -->

    <!-- Начало Template для типа CurrencyExchange -->
    <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'CurrencyExchange'">
        <div *ngFor="let item of checkDetails?.addInfo?.exchange | keyvalue">
            <div *ngIf="item.key == 'rate'">
                <mat-form-field fxFill>
                    <input matInput placeholder='{{item.key | translate}}' value="{{item.value | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}" disabled>
                </mat-form-field>
                <mat-form-field fxFill>
                    <input matInput placeholder='' [formControl]="rateCalc" value="" disabled>
                </mat-form-field>
            </div>  
        </div>
    </div>
    <!-- Конец Template для типа CurrencyExchange -->

    <!-- Начало Template для типа Parking -->
      <div *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'Parking'">
        <div *ngFor="let accountItem of checkDetails?.addInfo?.accounts?.account">
            <div *ngFor="let item of accountItem | keyvalue">
                <mat-form-field fxFill *ngIf="item.value.toString().length > 0">
                    <input matInput placeholder='{{item.key | translate}}' value='{{item.value}}' disabled>
                </mat-form-field>
            </div>
        </div>
    </div>
    <!-- Конец Template для типа Parking -->

    <!-- Начало Template для типа InvoiceTypeKTEK и InvoiceTypeUKTS -->
    <app-invoice-type-ktek (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && (checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeKTEK' || checkDetails?.serviceExtInfo?.serviceType == 'InvoiceTypeUKTS')
        " [checkDetails]="checkDetails"></app-invoice-type-ktek>
    <!-- Конец Template для типа InvoiceTypeKTEK и InvoiceTypeUKTS -->    

    <!-- Начало Template для типа SelectContract -->
    <app-invoice-type-select-contract (update)="countChange($event)" (changedServices)="billChanged($event)"
        *ngIf="recievedReference && checkDetails?.serviceExtInfo?.serviceType == 'SelectContract'
        " [checkDetails]="checkDetails"></app-invoice-type-select-contract>
    <!-- Конец Template для типа SelectContract -->
</div>

<div *ngIf="buttonRepay">
    <ng-container *ngIf="transactionInfo?.Payload?.bill?.Services?.length > 2 || checkDetails?.addInfo?.document?.invoices?.invoice[0]?.parameters?.parameter?.length >= 2">
            <button mat-flat-button fxFill (click)="pay()"
                [disabled]="!amount.valid || calculatedAmount <= 0" color="primary">{{'pay'| translate}}
                {{accepted | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</button>
    </ng-container>  
</div>

<mat-card class="excelTable" *ngIf="!this.isPayProcess.isPayStatus.getValue() && id == 300091"> <!--id необходимой услуги-->
  <div class="excelLoad">
    <div class="excelLoadInput">
      <label for="fileInput" class="fileInputLabel"><mat-icon>add</mat-icon></label>
      <input id="fileInput" type="file" accept=".xls,.xlsx" (change)="onFileSelected($event)" style="display: none;">
      <button *ngIf="seriesPaymentState == SeriesPaymentState.Selected" (click)="parseSeriesPaymentsFile()" [disabled]="isFileActionDisabled"><mat-icon>file_upload</mat-icon></button>
    </div>
    <div>
      <button *ngIf="seriesPaymentState == SeriesPaymentState.Parsed" (click)="checkSeriesPaymentsFile(template.maxAmount, template.minAmount)" [disabled]="isFileActionDisabled">{{'check'| translate}}</button>
      <button *ngIf="seriesPaymentState == SeriesPaymentState.Checked" (click)="paySeriesPayments()" [disabled]="isFileActionDisabled">{{'pay'| translate}}</button>
    </div>
  </div>

  <div fxLayout="column" fxLayoutAlign="none">
    <table mat-table *ngIf="seriesPaymentsData && seriesPaymentsData.length > 0" [dataSource]="seriesPaymentsData" class="mat-elevation-z0" fxFill>

      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>{{'excel-item-id'| translate}}</th>
        <td mat-cell *matCellDef="let item">{{ item.id }}</td>
      </ng-container>

      <!-- DateTime Column -->
      <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef>{{'excel-item-date'| translate}}</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="!item.editMode; else editModeTemplate">
          {{ item.dateTime | date:'yyyy-MM-dd HH:mm:ss' }}
          </ng-container>
          <ng-template #editModeTemplate>
            <input type="datetime-local" [(ngModel)]="item.dateTime">
          </ng-template>
        </td>
      </ng-container>

      <!-- AgentName Column -->
      <ng-container matColumnDef="agentName">
        <th mat-header-cell *matHeaderCellDef>{{'excel-item-agent'| translate}}</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="!item.editMode; else editModeTemplate">
            {{ item.agentName }}
          </ng-container>
          <ng-template #editModeTemplate>
            <input type="text" maxlength="30" [(ngModel)]="item.agentName">
          </ng-template>
        </td>
      </ng-container>

      <!-- VoyageCode Column -->
      <ng-container matColumnDef="voyageCode">
        <th mat-header-cell *matHeaderCellDef>{{'excel-item-voyage-code'| translate}}</th>
        <td mat-cell class="table-cell" *matCellDef="let item">
          <ng-container *ngIf="!item.editMode; else editModeTemplate">
            <div class="value-container">
              <span class="value">{{ item.voyageCode }}</span>
              <span class="errorTitle" *ngIf="!isValidVoyageCode(item.voyageCode)">*{{ 'invalid-input-error' | translate }}*</span>
            </div>
          </ng-container>
          <ng-template #editModeTemplate>
            <input type="number" [(ngModel)]="item.voyageCode" (ngModelChange)="onPaymentDetailsChange(item.id)">
          </ng-template>
        </td>
      </ng-container>

      <!-- AmountColumn -->
      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>{{'amount' | translate }}</th>
        <td mat-cell class="table-cell" *matCellDef="let item">
          <ng-container *ngIf="!item.editMode; else editModeTemplate">
            <div class="value-container">
              <span class="value">{{item.amount | currency:globalCurrencyCode:'symbol':'1.2-2':globalLocation}}</span>
              <span class="errorTitle" *ngIf="!isValidAmount(item.amount, template.maxAmount, template.minAmount)">*{{ 'invalid-input-error' | translate }}*</span>
              <span class="errorTitle" *ngIf="item.amount < template.minAmount/100">*{{'min-value-amount' | translate: {amount: template.minAmount/100} }}*</span>
              <span class="errorTitle" *ngIf="item.amount > template.maxAmount/100">*{{'max-value-amount' | translate: {amount: template.maxAmount/100} }}*</span>
            </div>
          </ng-container>
          <ng-template #editModeTemplate>
            <input type="number" [(ngModel)]="item.amount" [max]="template.maxAmount" (ngModelChange)="onPaymentDetailsChange(item.id)">
          </ng-template>
        </td>
      </ng-container>

      <!-- PaymentType Column -->
      <ng-container matColumnDef="paymentType">
        <th mat-header-cell *matHeaderCellDef>{{'excel-item-payment-type'| translate}}</th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="!item.editMode; else editModeTemplate">
            {{ item.paymentType }}
          </ng-container>
          <ng-template #editModeTemplate>
            <input type="text" [(ngModel)]="item.paymentType" maxlength="30">
          </ng-template>
        </td>
      </ng-container>

      <!-- CheckStatus Column -->
      <ng-container matColumnDef="checkStatus">
        <th mat-header-cell *matHeaderCellDef class="center-column">{{'report-status'| translate}}</th>
        <td mat-cell *matCellDef="let item" class="center-column">
          <ng-container *ngIf="item.isChecked; else unchecked">
            <mat-icon color="primary">done_outline</mat-icon>
            <span class="successTitle" >*{{'success-check-operation' | translate}}*</span>
          </ng-container>
          <ng-template #unchecked>
            <mat-icon color="warn">error_outline</mat-icon>
            <span class="errorTitle">*{{'invalid-check-operation' | translate}}*</span>
          </ng-template>
        </td>
      </ng-container>

      <!-- PayStatus Column -->
      <ng-container matColumnDef="payStatus">
        <th mat-header-cell *matHeaderCellDef class="center-column">{{'pay-status'| translate}}</th>
        <td mat-cell *matCellDef="let item" class="center-column">
          <ng-container *ngIf="seriesPaymentState === SeriesPaymentState.Paid">
            <ng-container *ngIf="item.IsSuccessPay; else unpaid">
              <mat-icon color="primary">done_outline</mat-icon>
              <span class="successTitle" >*{{'success-pay-operation' | translate}}*</span>
            </ng-container>
            <ng-template #unpaid>
              <mat-icon color="warn">error_outline</mat-icon>
              <span class="errorTitle">*{{'invalid-pay-operation' | translate}}*</span>
            </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- EditSection Column -->
      <ng-container matColumnDef="editSection">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="seriesPaymentState != SeriesPaymentState.Paid">
            <ng-container *ngIf="!item.editMode; else editModeTemplate">
            <button mat-icon-button class="EditSectionButton" (click)="enableEditMode(item)">
              <mat-icon>edit</mat-icon>
            </button>
          </ng-container>
          <ng-template #editModeTemplate>
            <button mat-icon-button class="EditSectionButton" (click)="disableEditMode(item)">
              <mat-icon>done</mat-icon>
            </button>
          </ng-template>
          </ng-container>
        </td>
      </ng-container>

      <!-- DeleteSection Column -->
      <ng-container matColumnDef="deleteSection">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let item">
          <ng-container *ngIf="item">
            <button mat-icon-button class="EditSectionButton" (click)="deletePaymentCell(item.id)">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</mat-card>
