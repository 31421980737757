import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ITransaction } from '../interfaces/payment/itransaction';
import { ICheckCommand } from '../interfaces/payment/icheck-command';
import {IseriesCheckRequest} from '../interfaces/payment/IseriesCheckRequest';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  host: string;

  constructor(private http: HttpClient) {
    this.host = environment.api_url + '/transaction'
  }

  getCommission(serviceId: number, amount: number, included: boolean): Observable<any> {
    let url = this.host+"/"+serviceId+"/"+Math.floor(amount).toString()+"/"+included;   
    return this.http.get<any[]>(url);
  };

  create(json: ITransaction, isDivisionalPayment: boolean): Observable<any> {
    let url = this.host;
    if(isDivisionalPayment == true){
      url = this.host + "/divisional";
    }
    return this.http.post<any[]>(url, json);
  };

  // Оплата серийных платежей
  createSeries(json: ITransaction[]): Observable<any> {
    const url = this.host + '/series-pay';
    return this.http.post<any[]>(url, json);
  }

  // Получить данные транзакции
  get(transactionId: string): Observable<any> {
    let url = this.host + '/' + transactionId;
    return this.http.get<any>(url);
  }

  getTemplate(serviceId: string): Observable<any> {
    let url = this.host+'/template?ServiceId=' + serviceId;
    return this.http.get<any[]>(url);
  };

  check(json: ICheckCommand): Observable<any> {
    let url = this.host + '/check';
    return this.http.post<any[]>(url, json);
  };

  // Проверка серийных платежей
  seriesPaymentCheck(json: IseriesCheckRequest): Observable<any>{
   return this.http.post<any[]>(this.host + '/series-check', json);
  }

  // Получить данные транзакции для дробления
  getDivisionalTransaction(id: string): Observable<any> {
    let url = this.host + '/divisional/' + id;
    return this.http.get<any>(url);
  }

  // Парсинг excel файла
  parseSeriesPaymentsFile(data: FormData){
    const url = environment.api_url + '/SeriesPayments/parse';
    return this.http.post<any>(url, data);
  }
}

