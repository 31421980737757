<div fxLayout="column" fxLayoutAlign="center center">
    <h1 class="mat-headline">{{'news-and-notifications'| translate}}</h1>
</div>

<div class="container mb-20" fxLayout fxLayoutAlign="end center" fxLayoutGap="20px" fxLayout.xs="column">
    <button mat-flat-button (click)="changeNewsStatus(true)" color="primary" *ngIf="selectedCount > 0">
        <mat-icon>drafts</mat-icon>
        {{'mail-as-readed'| translate}}
    </button>

    <button mat-flat-button (click)="changeNewsStatus(false)" color="primary" *ngIf="selectedCount > 0">
        <mat-icon>markunread</mat-icon>
        {{'mail-as-unreaded'| translate}}
    </button>

    <div fxLayout="row" fxLayoutAlign="center center">
        <mat-chip-list>
            <mat-chip (click)="openNewsFilters()" *ngIf="dateFrom?.value">{{'from-date' | translate}} {{dateFrom?.value | date: 'dd/MM/yyyy'}}</mat-chip>
            <mat-chip (click)="openNewsFilters()" *ngIf="dateTo?.value">{{'to-date' | translate}} {{dateTo?.value | date: 'dd/MM/yyyy'}}</mat-chip>
            <mat-chip (click)="openNewsFilters()" [ngSwitch]="statusNews">
                <span *ngSwitchCase = "-1">{{'select-all' | translate}}</span>
                <span *ngSwitchCase = "1">{{'readed' | translate}}</span>
                <span *ngSwitchCase = "0">{{'not-readed' | translate}}</span>
            </mat-chip>
        </mat-chip-list>

        <button mat-icon-button color="primary" (click)="openNewsFilters()">
            <mat-icon>filter_list</mat-icon>
        </button>
    </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="!isCompleted"></mat-progress-bar>
<div fxLayout="column" fxLayoutAlign="none">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" multiTemplateDataRows>

        <!-- selectCheckbox Column -->
        <ng-container matColumnDef="selectCheckbox">
            <th mat-header-cell *matHeaderCellDef class="checkbox-td-width">
                <mat-checkbox [(ngModel)]="isSelectedAll" (change)="updateAllSelectedCount($event)"></mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let element" class="checkbox-td-width">
                <mat-checkbox [(ngModel)]="element.isSelected" (change)="updateSelectedCount()"></mat-checkbox>
            </td>
        </ng-container>

        <!-- titleInfo Column -->
        <ng-container matColumnDef="titleInfo">
            <th mat-header-cell *matHeaderCellDef> {{'title' | translate}} </th>
            <td mat-cell *matCellDef="let element" (click)="readNews(expandedElement, element)">
                <span class="mat-body" [class.bold]="!element.userNewsStatus">{{element.title}} </span> <br>
                <span class="mat-caption" *ngIf="element.tariffName">{{'template' | translate}}: {{element.tariffName}}
                    <br></span>
                <span class="mat-caption">{{element.newsDate | date: 'dd/MM/yyyy HH:mm:ss'}}</span>
            </td>
        </ng-container>

        <!-- expanderBlock Column -->
        <ng-container matColumnDef="expanderBlock">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" (click)="readNews(expandedElement, element)">
                <mat-icon *ngIf="expandedElement !== element" style="float: right;">keyboard_arrow_down</mat-icon>
                <mat-icon *ngIf="expandedElement === element" style="float: right;">keyboard_arrow_up</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length"
                style="padding: 0; border-bottom: none;">
                <div class="element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div fxFill fxLayoutGap="10px" *ngIf="element.newsType == 0">
                        <div fxFlex="50%">
                            <span class="mat-body">{{'commission-before' | translate}}</span><br>
                            <span class="mat-caption" [innerHTML]="element.commissionBefore"></span>
                        </div>
                        <div fxFlex="50%">
                            <span class="mat-body">{{'commission-after' | translate}}</span><br>
                            <span class="mat-caption" [innerHTML]="element.commissionAfter"></span>
                        </div>
                    </div>
                    <!-- News text -->
                    <div fxFill fxLayoutGap="10px" *ngIf="element.newsType == 1 || element.newsType == 2">
                        <div fxFlex="100%">
                          <img src="{{staticApiUrl}}ukassa/{{element.image}}" style="width: 200px;" alt="">
                          <div *ngIf="element.text" class="mat-body news-text" [innerHTML]="element.text"></div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="element-row"
            [class.expanded-row]="expandedElement === element"
            [class.expanded-row-style]="element.newsType == 0 || element.newsType == 1  || element.newsType == 2"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="2">{{'empty-report-result' | translate}}</td>
        </tr>
    </table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[10, 25, 100, 500, 1000, 5000, 10000]"></mat-paginator>
</div>
