<div [formGroup]="form">
    <div [ngSwitch]="inputcontrol.controlType">
        <mat-form-field fxFill *ngSwitchCase="'Dictionary'">
            <mat-label>{{inputcontrol.label | lowercase | translate}}</mat-label>
            <mat-select [formControlName]="inputcontrol.key" [id]="inputcontrol.key" [disabled]="isPay ? true : false">
                <mat-option *ngFor="let opt of ((inputcontrol.options | keyvalue))" [value]="opt.key">
                    {{opt.value}}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="!isValid.valid && !isValid.errors.pattern">
                {{'select' | translate}} {{inputcontrol.label | lowercase | translate}}
                {{'select' | translate}} {{inputcontrol.label | lowercase | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFill *ngSwitchDefault>
            <mat-label>{{inputcontrol.label | lowercase | translate}}</mat-label>
            <input matInput placeholder="{{inputcontrol.sample}}" value="" [id]="inputcontrol.key"
                [type]="inputcontrol.type" [formControlName]="inputcontrol.key" prefix="{{inputPrefix}}"
                mask="{{inputMask}}" pattern="{{inputcontrol.regex}}" [attr.disabled]="isPay ? true : false">

            <mat-error *ngIf="!isValid.valid && !isValid.errors.pattern">
<!--                {{'phone-enter-template' | translate}}-->
                 {{'dial' | translate}} {{inputcontrol.label | lowercase | translate}}
            </mat-error>
            <mat-error *ngIf="isValid.errors && isValid.errors.pattern">
                {{'incorrectFormat' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
</div>
