import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {distinctUntilChanged, pairwise, switchMap} from 'rxjs/operators';
import {ITemplate} from 'src/app/interfaces/payment/itemplate';
import {ICheckCommand} from 'src/app/interfaces/payment/icheck-command';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ICheckDetails} from 'src/app/interfaces/check-details/icheck-details';
import {InputControlBase} from 'src/app/classes/input-control-base';
import {InputControlService} from 'src/app/services/payments/input-control.service';
import {TransactionService} from 'src/app/services/transaction.service';
import {ITransaction} from 'src/app/interfaces/payment/itransaction';
import {IBill} from 'src/app/interfaces/payment/ibill';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from 'src/app/services/loader.service';
import {UpperCommissionComponent} from '../../agent/rates/upper-commission/upper-commission.component';
import {LowerCommissionComponent} from '../../agent/rates/lower-commission/lower-commission.component';
import {MatDialog} from '@angular/material/dialog';
import {ServiceService} from 'src/app/services/service.service';
import {CookieService} from 'ngx-cookie-service';
import {IsPayProcessService} from 'src/app/services/is-pay-process.service';
import {environment} from 'src/environments/environment';
import {DivisionalPaymentsConfirmDialogComponent} from '../divisional-payments-confirm-dialog/divisional-payments-confirm-dialog.component';
import {RepayDialogComponent} from '../repay-dialog/repay-dialog.component';
import {ISeriesPaymentsData} from '../../../interfaces/payment/iseriesPaymentsData';
import {IseriesCheckRequest} from '../../../interfaces/payment/IseriesCheckRequest';
import {SeriesPaymentState} from '../../../enums/SeriesPaymentStatesEnum';


@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  elementsArr: [];
  isInvoice: boolean = false;
  @Input() inputcontrols: InputControlBase<string>[] = [];
  @Input() payInputcontrols: InputControlBase<string>[] = [];
  bill: IBill;
  checkDetails: ICheckDetails; // Детали операции check
  recievedReference: string; // Ответ операции Check
  amount = new FormControl({ value: 0, disabled: false }, [Validators.required, Validators.min(1), Validators.max(490000)]); // сумма к оплате
  isIncludeCommission = new FormControl({ value: true, disabled: true }); // комиссия включена в платеж
  calculatedAmount: number = 0;
  accepted: number = 0;
  prevAmount: number = 0;
  repayStatus : boolean = false;

  selectedFile: File | null = null;
  seriesPaymentState: SeriesPaymentState = SeriesPaymentState.Initial; // Определение состояния серийного платежа
  SeriesPaymentState = SeriesPaymentState;
  seriesPaymentsData: ISeriesPaymentsData[] = [];
  displayedColumns: string[] = ['id', 'dateTime', 'agentName', 'voyageCode', 'amount', 'paymentType', 'checkStatus', 'payStatus', 'editSection', 'deleteSection'];
  isFileActionDisabled = false;
  excelAmountRegex = /^(?!0$)\d+(\.\d{1,2})?$/;
  excelVoyageCodeRegex = /^(?!0$)\d{1,50}$/;
  seriesCheckCommand: IseriesCheckRequest;

  form: FormGroup;
  // форма данных для отправки на pay
  payForm: FormGroup;
  upperCommissionInfo: any;

  data: ICheckCommand;
  transaction: ITransaction = null;
  transactionId: string;
  transactionInfo : any;
  infoTransactionId: number;
  
  id: number; // Идентификатор услуги
  public template: ITemplate; // Полученный шаблон

  public commission: number = 0; // Комиссия
  isCompleted: boolean = false;
  onDemandAmount: boolean = false;
  rateCalc = new FormControl({ value: 0, disabled: true });
  inputPrefix: string;
  requestParamPrefix: string;
  inputMask: string;
  serviceName: string;
  categoryName: string;
  static_api_url: string;
  buttonRepay: boolean = false;
  repayTransaction : any;
  // глобальные параметры валюты приложения
  globalLocation = environment.globalLocation;
  globalCurrencyCode = environment.globalCurrencyCode;
  isDivisionalPayment: boolean = false;
  @ViewChild('amountel', {static: false})amountElement: ElementRef;

  constructor(private route: ActivatedRoute,
    private qcs: InputControlService,
    private transactionSrv: TransactionService,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private router: Router,
    public dialog: MatDialog,
    private serviceSrv: ServiceService,
    private cookieService: CookieService,
    public isPayProcess: IsPayProcessService) {
        // функция нужна для получения пред. значения суммы к оплате
        this.amount.valueChanges.pipe(
          distinctUntilChanged(),
          pairwise() // gets a pair of old and new value
        ).subscribe(([oldValue, newValue])=>{
          this.prevAmount = oldValue;
        })

        this.static_api_url = environment.api_static;
    /*     route.params.subscribe(val => {
          // put the code from `ngOnInit` here
          this.isInvoice = false;
          this.inputcontrols = [];
          this.bill =null;
          this.checkDetails=null;
          this.recievedReference = '';
          this.amount =new FormControl({ value: 0, disabled: false }, [Validators.required, Validators.min(1), Validators.max(500000)]); // сумма к оплате
          this.isIncludeCommission = new FormControl({ value: false, disabled: true }); // комиссия включена в платеж
          this.calculatedAmount =0;
          this.accepted =0;
          this.template = null;
          this.load();
        });  */
  }

  @HostListener('keydown', ['$event']) onKeyDown(e) {
    this.getFocusInput(e)
  }
  

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('id'))
    )
      .subscribe(data => this.id = +data);

      // Id  транзакции для повтора платежа
    this.route.paramMap.pipe(
      switchMap(params => params.getAll('transactionId'))
    )
      .subscribe(data => this.infoTransactionId  =+ data);

      if(this.infoTransactionId > 0){
        this.transactionSrv.get(this.infoTransactionId.toString()).subscribe(
          dataTransaction => {
            this.transactionInfo = dataTransaction;
            this.getTemplate();
          },
          error => {
            let message = this.translate.instant('transaction-data-not-found');
            this.openSnackBar(message, 'x');
            this.isCompleted = true;
            return false;
          }
        );
      }
      else{
        this.getTemplate();
      }
  } 

  getFocusInput(e:any){
    if ((e.keyCode == 13 && e.target.getAttribute('tabindex')>=0)){
      let elementsArr = [];
      let currentTabIndex = (e.target.getAttribute('tabindex'));
      let serviceBlockList = document.querySelectorAll('.service-block-new');
      if(serviceBlockList.length > 0){
        let serviceBlockElementList = serviceBlockList[0].querySelectorAll("input");
        for (var i=0; i <serviceBlockElementList.length; i++) {
          if(serviceBlockElementList[i]?.getAttribute('tabindex') != undefined && parseInt(serviceBlockElementList[i]?.getAttribute('tabindex')) > 0){
            elementsArr.push(serviceBlockElementList[i]);
          }
        }
        for(var i = 0; i < elementsArr.length-1; i++){
          var elementTabIndex = parseInt(elementsArr[i].getAttribute('tabindex'));
            if (elementTabIndex == currentTabIndex){
              elementsArr[i+1].focus();
              break;
            }
        }  
      }
    }   
  }

  setStartFocusInput(elementsArr){
    var indexArray = [];
    let minIndex: number;
      for (let i = 0; i < elementsArr.length; i++) {
        if(elementsArr[i].getAttribute('tabindex') > 0){
          indexArray.push(parseInt(elementsArr[i].getAttribute('tabindex')));
        }  
      }

      elementsArr.find(s=>s.getAttribute('tabindex') == Math.min.apply(Math, indexArray))?.focus();
      this.showButtonRepay() 
  }

  showButtonRepay(){
    if(this.recievedReference && this.transactionInfo?.Payload?.bill?.Services?.length > 1 ){
       this.buttonRepay = true;
    }   
    else if (this.checkDetails?.serviceExtInfo?.serviceType.includes('Invoice') || this.checkDetails?.serviceExtInfo?.serviceType == 'SelectContract'){
      this.buttonRepay = true;    
    } 
    else{
      this.buttonRepay = false; 
    }      
  }

  getArrayInput(){
    let elementsArr = [];
    let serviceBlockList = document.getElementsByTagName('input');
    for (let i = 0; i < serviceBlockList.length; i++) {
      if(serviceBlockList[i]?.getAttribute('tabindex') != undefined && parseInt(serviceBlockList[i]?.getAttribute('tabindex')) > 0){
         elementsArr.push(serviceBlockList[i]);
      }
    }
    this.setStartFocusInput(elementsArr);
  }

  // Получить шаблон
  getTemplate() {
    this.isPayProcess.set(false);
    this.isCompleted = false;
    if (this.id != null && this.id != undefined) {
      this.serviceSrv.search(this.id.toString()).subscribe(
        (dataService: any) => {
          this.transactionSrv.getTemplate(this.id.toString()).subscribe(
              (data: any) => {
                this.template = data;
                this.template?.parameters.forEach(element => {
                    if(element.isForPay == true){
                      this.payInputcontrols.push({
                        key: element.verbalCode, label: element.verbalCode, order: element.order,
                        type: "text", value: "", required: false, controlType: element.type, description: element.description,
                        options: element.dictionary, sample: element.sample, mask: element.mask, isForPay: element.isForPay, regex: element.regex
                      });
                    }
                    else{
                      let inputControlsValue = "";
                      if(this.transactionInfo?.Payload?.parameters[element.verbalCode]){
                        inputControlsValue = this.transactionInfo?.Payload?.parameters[element.verbalCode];
                      }
                      this.inputcontrols.push({
                        key: element.verbalCode, label: element.verbalCode, order: element.order,
                        type: "text", value: inputControlsValue, required: true, controlType: element.type, description: element.description,
                        options: element.dictionary, sample: element.sample, mask: element.mask, isForPay: element.isForPay, regex: element.regex
                      });
                    }
                    
                });
      
                var validator_arr = [];
                if(data.minAmount/100 > 0){
                    validator_arr.push(Validators.min(data.minAmount/100));
                }
                else{
                    validator_arr.push(Validators.min(1));
                }

                if(this.id == 310036){
                  validator_arr.push(Validators.max(490000));
                }
                else{
                  if(data.maxAmount/100 > 0 && data.maxAmount/100 < 490001){
                      validator_arr.push(Validators.max(data.maxAmount/100));
                  }
                  else{
                    validator_arr.push(Validators.max(490000));
                  }
                }
      
                if(validator_arr.length > 0){
                   this.amount.clearValidators();
                   validator_arr.push(Validators.required);
                   this.amount.setValidators(validator_arr);
                   this.amount.updateValueAndValidity();  
                }
      
                this.form = this.qcs.toFormGroup(this.inputcontrols);
                this.inputPrefix = dataService[0]?.prefix;
                this.requestParamPrefix = dataService[0]?.requestParamPrefix;
                this.inputMask = dataService[0]?.mask;
                this.serviceName = dataService[0]?.name;
                this.categoryName = dataService[0]?.categoryName;

                this.cookieService.delete('catId');
                this.cookieService.set( 'catId', dataService[0]?.categoryId);
                this.onDemandAmount = data.onDemandAmount;
                if(this.infoTransactionId > 0){
                  let countTransactionInfoParam = 0;
                  if(this.transactionInfo?.Payload?.parameters){
                    countTransactionInfoParam = Object.keys(this.transactionInfo?.Payload?.parameters)?.length;
                  }
                  if(this.template?.parameters?.length != countTransactionInfoParam){
                    let message = this.translate.instant('repeat-transaction-parameters-error');
                    this.openSnackBar(message, 'x');
                    return;
                  }
                  this.checkPay()
                }
              },
              error => {
                let message = this.translate.instant('template-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
              }
            );
        },
        error => {
          let message = this.translate.instant('template-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        }
      ).add(() => { this.isCompleted = true });
    
    }
  }


  // Проверка возможности оплаты
  checkPay() {
    if (this.id != null && this.id != undefined) {      
      //http call starts
      this.loaderService.display(true);
      // Тут костыль в виде 500, т.к. нельзя передать в check amount 0
      this.data = { 'serviceId': this.id, 'parameters': this.form.getRawValue(), 'amount': (this.template.minAmount > 0 ? this.template.minAmount : 500) * 100, 'requestParamPrefix': this.requestParamPrefix };
      this.transactionSrv.check(this.data).subscribe(
        data => {
          if (data.code == 2010 || data.code == 2000) {
            if(data.RepayTransaction != null && data.IsCheckRepay == true)
            {
              this.repayTransaction = JSON.parse(data.RepayTransaction);
              this.openRepayDialog();       
            }
            
            this.recievedReference = data.reference;
            this.checkDetails = data.details;
            if (this.checkDetails?.information?.amount > 0){

                // для обменников вызывается расчет суммы в указанной currency
                if(this.checkDetails?.serviceExtInfo?.serviceType == "CurrencyExchange"){
                  this.calculateExchange();
                }
                
                this.amount.setValue(this.checkDetails.information.amount / 100);
            }
            else if (this.transactionInfo?.Payload?.accepted > 0){
              this.amount.setValue(this.transactionInfo?.Payload?.accepted / 100);
            }
            // Если услуга инвойсовая, то запретить редактирование суммы платежа
            if (this.recievedReference && this.checkDetails?.invoice) {
                if(this.checkDetails?.serviceExtInfo?.serviceType == "InvoiceTypeOnlyLastCounts" || this.checkDetails?.serviceExtInfo?.serviceType == "SelectContract"){
                  this.isInvoice = this.onDemandAmount;
                }
                else if(this.checkDetails?.serviceExtInfo?.serviceType != "InvoiceTypeOnlyLastCounts"){
                  this.isInvoice = true;
                }
            }  

            if(this.isInvoice == true || this.onDemandAmount == true){
                this.isIncludeCommission.setValue(false);
            }

            // Получение комиссии
            this.getCommission();

            // Признак этапа оплаты
            this.isPayProcess.set(true);
          }
          else {
            if (data.code == 4041) {
              let message = data.details.message;
              this.openSnackBar(message, 'x');

            }
          }
        },
        error => {
          if (error.status === 409) {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
          else
            if (error.status === 400) {
              let message = this.translate.instant('not-found');
              this.openSnackBar(message, 'x');
            }
            else
              if (error.status === 500) {
                
                let message = this.translate.instant('service-is-temporarily-unavailable');
                if (error.error?.code == 5000 || error.error?.code == 4000 || error.error?.code == 5030) {
                    message = error.error?.details?.message;
                }
                this.openSnackBar(message, 'x');
              }
              else {
                let message = this.translate.instant('an-error-occurred-while-processing');
                this.openSnackBar(message, 'x');
              }
        }
      ).add(() => {
        //http call ends
        this.loaderService.display(false);
      });
    }
  }

  // Вызов формы подтверждения платежа
  openRepayDialog() {
    const dialogRefDivisional = this.dialog.open(RepayDialogComponent,
      { data: { transaction: this.repayTransaction },
        disableClose: true
      });

      dialogRefDivisional.afterClosed().subscribe((data: any) => {
        this.repayStatus = data['repayStatus'];
        if(data.isClosed == true){
            window.location.href = "/";
        }
    });
  }

  // расчет суммы в заданной в template валюте 
  calculateExchange(){
    this.rateCalc.setValue(Number(this.amount.value/parseFloat(this.checkDetails?.addInfo?.exchange?.rate)).toFixed(2) + " " + this.checkDetails?.addInfo?.exchange?.currency);
  }

  // Получение комиссии
  getCommission() {
    if (this.id != null && this.id != undefined) {
      //http call starts
      this.loaderService.display(true);
      this.isIncludeCommission.disable();

      if(this.checkDetails?.serviceExtInfo?.serviceType == "CurrencyExchange"){
          this.calculateExchange();
      }

      this.transactionSrv.getCommission(this.id, this.amount.value * 100, this.isIncludeCommission.value).subscribe(
        data => {
          this.commission = data.upper / 100;
          this.upperCommissionInfo = data;
          if (this.commission < this.amount.value) { // активируем бегунок, если коммиссия меньше суммы платежа
            this.isIncludeCommission.enable();
          }
          if (this.isIncludeCommission.value == true) {
            this.calculatedAmount = this.amount.value - this.commission;
            this.accepted = this.amount.value;
          }
          else {
            this.calculatedAmount = this.amount.value;
            this.accepted = this.amount.value + this.commission;
          }
        },
        error => {
          if (error.status === 500) {
            let message = this.translate.instant('service-is-temporarily-unavailable');
            this.openSnackBar(message, 'x');
          }
          else {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
        }
      ).add(() => {
        //http call ends
        this.loaderService.display(false);
      });
    }
  }

  countChange(event) {
    this.amount.setValue(event);
    this.getCommission();
  }

  billChanged(event) {
    this.bill = event;
  }

  pay() {
    if(this.id == 310036 && this.amount.value*100 > this.template.maxAmount){
      const dialogRefDivisional = this.dialog.open(DivisionalPaymentsConfirmDialogComponent,
        {  panelClass: 'confirm-divisional-transaction-dialog',
           data: { 'amount': this.amount.value, maxAmount: this.template.maxAmount/100  }
        });

        dialogRefDivisional.afterClosed().subscribe((dialogData: any) => {
          if (dialogData.confirmed) {
              this.isDivisionalPayment = true;
              this.sendPay();
          }
          else{
            this.dialog.closeAll();
          }
      });
    }
    else{
      this.isDivisionalPayment = false;
      this.sendPay();
    }
  }

  sendPay(){
    if(!this.amount.valid){
        return false;
    }

    if(!this.form.valid){
      let message = this.translate.instant('incorrect-payment-parameters');
      this.openSnackBar(message, 'x');
      return false;
    }

    if(this.checkDetails?.serviceExtInfo?.serviceType == "InvoiceTypeOnlyLastCounts"){
      this.bill.total = Math.round((Number(Number(this.amount.value).toFixed(2)) * 100));
    }

    // формирование данных для отправки на pay из payInputcontrols и полей формы Check
    this.payForm = null;
    this.payForm = this.qcs.createPayFormgroup(this.payInputcontrols,this.bill?.services[0]['id']);
    Object.keys(this.form.controls).forEach((key: string) => {
      this.payForm.addControl(key, new FormControl(this.form.get(key).value || ''));
    });
    
    let json: ITransaction =
    {
      serviceId: this.id,
      accepted: Math.round((Number(Number(this.accepted).toFixed(2)) * 100)),
      amount: Math.round((Number(Number(this.calculatedAmount).toFixed(2)) * 100)),
      estimatedAmount: Math.round((Number(Number(this.amount.value).toFixed(2)) * 100)),
      bill: this.bill,
      parameters: this.payForm.getRawValue(),
      reference: this.recievedReference,
      included: this.isIncludeCommission.value,
      fee: Math.round((Number(Number(this.commission).toFixed(2)) * 100)),
      serviceName: this.serviceName,
      AddInfo: this.checkDetails?.information,
      requestParamPrefix: this.requestParamPrefix,
      maxAmount: this.template.maxAmount
    };

    if (this.id != null && this.id != undefined) {
      //http call starts
      this.loaderService.display(true);
      this.transactionSrv.create(json, this.isDivisionalPayment).subscribe(
        data => {
          this.transactionId = data;
          if(this.isDivisionalPayment == true){
              window.location.href = "/payment/success-divisional/" + this.transactionId;
          }
          else{
              window.location.href = '/payment/success/' + this.transactionId;
          }
        },
        error => {
          if (error.status === 409 || error.status === 400 || error.status === 408) {
            let message = error.error;
            this.openSnackBar(message, 'x');
          }
          else if (error.status === 500) {
                let message = this.translate.instant('service-is-temporarily-unavailable');
                this.openSnackBar(message, 'x');
          }
          else {
            let message = this.translate.instant('an-error-occurred-while-processing');
            this.openSnackBar(message, 'x');
          }
        }
      ).add(() => {
        //http call ends
        this.loaderService.display(false);
      });
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  // View upper commission
  openAgentUpperCommissionDialog(item: any) {
    const dialogRef = this.dialog.open(UpperCommissionComponent,
      { data: { service: { 'serviceId': this.id, 'upperCommissionInfo': this.upperCommissionInfo, 'isIncluded': this.isIncludeCommission.value, 'amount': this.amount.value } } });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  // View lower commission
  openAgentLowerCommissionDialog(item: any) {
    const dialogRef = this.dialog.open(LowerCommissionComponent,
      { data: { service: { 'serviceId': this.id, 'amount': this.calculatedAmount } } });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
    

  // автоматом убирает 0 при наборе суммы к оплате
  onKeypressAmountEvent(event: any){
    if(this.isInvoice == false && this.onDemandAmount == false && Number(this.prevAmount) == 0){
        this.amount.patchValue(Number(event.key));
        this.amountElement.nativeElement.setSelectionRange(1, 1, 'none');
    }
  }

  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile && this.selectedFile.size > 0) {
      this.seriesPaymentState = SeriesPaymentState.Selected;
      this.seriesPaymentsData = null;
    }
  }

  parseSeriesPaymentsFile() {
    if (this.isFileActionDisabled) {
      return;
    }
    this.isFileActionDisabled = true;
    this.loaderService.display(true);

    const formData = new FormData();
    formData.append('ExcelFile', this.selectedFile);

    this.transactionSrv.parseSeriesPaymentsFile(formData).subscribe(
      response => {
        this.seriesPaymentsData = response;
        this.seriesPaymentState = SeriesPaymentState.Parsed;
      },
      error => {
        this.seriesPaymentsData = null;
        const message = this.translate.instant('an-error-occurred-while-processing');
        this.openSnackBar(message, 'x');
        if (error.status === 400) {
          const errorMessage = this.translate.instant('invalid-file-input');
          this.openSnackBar(errorMessage, 'x');
        }
      }
    ).add(() => {
      this.loaderService.display(false);
      setTimeout(() => {
        this.isFileActionDisabled = false;
      }, 3000);
    });
  }

  enableEditMode(item: any) {
    item.editMode = true;
  }

  disableEditMode(item: any) {
    item.editMode = false;
  }

  deletePaymentCell(paymentDataId: number) {
    this.seriesPaymentsData = this.seriesPaymentsData.filter(item => item.id !== paymentDataId);
  }

  isValidAmount(amount: number | null, maxAmount: number, minAmount: number){
    if (amount !== null && !isNaN(amount)) {
      const amountValue = amount.toString();
      return this.excelAmountRegex.test(amountValue) || amount > maxAmount || amount < minAmount;
    }
    return false;
  }

  isValidVoyageCode(code: number | null) {
    if (code !== null && !isNaN(code)) {
       const codeValue = code.toString();
       return this.excelVoyageCodeRegex.test(codeValue);
    }
    return false;
  }

  onPaymentDetailsChange(paymentDataId: number){
    this.seriesPaymentState = SeriesPaymentState.Parsed;
    const paymentData = this.seriesPaymentsData.find(item => item.id === paymentDataId);
    if (!paymentData) {
      return;
    }
    paymentData.isChecked = false;
  }

  checkSeriesPaymentsFile(maxAmount: number, minAmount: number){
    if (this.isFileActionDisabled){
      return;
    }
    if (!this.seriesPaymentsData.length){
      const message = this.translate.instant('invalid-data-input');
      this.openSnackBar(message, 'x');
      return;
    }
    if (!this.seriesPaymentsData.every(item =>
      this.isValidAmount(item.amount, maxAmount, minAmount) &&  this.isValidVoyageCode(item.voyageCode))){
      const message = this.translate.instant('invalid-data-input');
      this.openSnackBar(message, 'x');
      return;
    }
    this.removeReferences();
    this.checkSeriesPay();
    setTimeout(() => {
      this.isFileActionDisabled = false;
    }, 5000);
  }

  checkSeriesPay() {
    if (this.isEditModEnabled()){
      const message = this.translate.instant('edit-mode-warning');
      this.openSnackBar(message, 'x');
      return;
    }

    this.isFileActionDisabled = true;
    if (this.seriesPaymentsData != null && this.seriesPaymentsData.length > 0) {
      this.loaderService.display(true);
      this.seriesPaymentsData.forEach(item => {
          if (!item.parameters) {
            item.parameters = {};
          }
          item.parameters['Account'] = item.voyageCode.toString();
      });
      this.seriesCheckCommand = {
        serviceId: this.id,
        seriesPaymentsData: this.seriesPaymentsData,
        amount: (this.template.minAmount > 0 ? this.template.minAmount : 500) * 100};
      this.transactionSrv.seriesPaymentCheck(this.seriesCheckCommand).subscribe(
        data => {
          this.updateReferences(data);
          this.isPaymentsDataChecked();
        },
          error => {
          const message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
      ).add(() => {
        this.loaderService.display(false);
      });
    }
  }

  isEditModEnabled(){
    return this.seriesPaymentsData.some(item => item.editMode);
  }

  updateReferences(serverData: any[]) {
    serverData.forEach(paymentData => {
      const existingPayItem = this.seriesPaymentsData.find(item => item.id === paymentData.id);
      if (existingPayItem){
        existingPayItem.checkResponse = JSON.parse(paymentData.checkResponse);

        if (existingPayItem.checkResponse === null) {
          return;
        }

        const code = existingPayItem.checkResponse.code;
        if (code === 2010 || code === 2000){
          existingPayItem.isChecked = paymentData.isChecked || false;
        }
      }
    });
  }

  removeReferences(){
    this.seriesPaymentsData.forEach(paymentData => {
      if (paymentData.checkResponse){
        paymentData.checkResponse = undefined;
      }
    });
  }

  isPaymentsDataChecked(){
    if (this.seriesPaymentsData.every(item => item.isChecked)){
      this.seriesPaymentState = SeriesPaymentState.Checked;
    }
    else{
      const message = this.translate.instant('invalid-series-payment-check-operation');
      this.openSnackBar(message, 'x');
    }
  }

  paySeriesPayments(){
    if (this.isFileActionDisabled) {
      return;
    }
    if (!this.seriesPaymentsData.length){
      const message = this.translate.instant('invalid-data-input');
      this.openSnackBar(message, 'x');
      return;
    }

    if (!this.seriesPaymentsData.every(item =>
      this.isValidAmount(item.amount, this.template.maxAmount, this.template.minAmount) &&  this.isValidVoyageCode(item.voyageCode))){
      const message = this.translate.instant('invalid-data-input');
      this.openSnackBar(message, 'x');
      return;
    }
    this.sendSeriesPay();
  }

  sendSeriesPay(){
    this.loaderService.display(true);

    const body: ITransaction[] = [];
    this.seriesPaymentsData.forEach(paymentData => {
      body.push({
        serviceId: this.id,
        accepted: Math.round((Number(Number(paymentData.amount).toFixed(2)) * 100)),
        amount: Math.round((Number(Number(paymentData.amount).toFixed(2)) * 100)),
        estimatedAmount: Math.round((Number(Number(paymentData.amount).toFixed(2)) * 100)),
        bill: null,
        parameters: paymentData.parameters,
        reference: paymentData.checkResponse.reference,
        included: true,
        fee: 0,
        serviceName: this.serviceName,
        AddInfo: null,
        requestParamPrefix: null,
        maxAmount: this.template.maxAmount,
        cellId: paymentData.id
      });
    });

    this.transactionSrv.createSeries(body).subscribe(data => {
        this.setPayStatus(data);
        this.seriesPaymentState = SeriesPaymentState.Paid;
    },
      error => {
        this.seriesPaymentsData.forEach(paymentData => {
          paymentData.isChecked = false;
          paymentData.IsSuccessPay = false;
        });
        this.seriesPaymentState = SeriesPaymentState.Parsed;
        if (error.status === 409 || error.status === 400 || error.status === 408) {
          const message = error.error;
          this.openSnackBar(message, 'x');
        }
        else if (error.status === 500) {
          const message = this.translate.instant('service-is-temporarily-unavailable');
          this.openSnackBar(message, 'x');
        }
        else {
          const message = this.translate.instant('an-error-occurred-while-processing');
          this.openSnackBar(message, 'x');
        }
      }).add(() => {
      this.loaderService.display(false);
    });
  }

  setPayStatus(serverData: any[]) {
    serverData.forEach(paymentData => {
      const existingPayItem = this.seriesPaymentsData.find(item => item.id === paymentData.cellId);
      if (existingPayItem){
        existingPayItem.IsSuccessPay = paymentData.isSuccessPay;
      }
    });
  }
}
